import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

const Quote = () => {
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");

  const fetchQuoteByCategory = useCallback(async (category) => {
    const apiUrl = `https://api.api-ninjas.com/v1/quotes?category=${category}`;
    const apiKey = "UvR+1AB64vIGJeoB+hkvDQ==qDRDw2kumfQlpVtn";

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          "X-Api-Key": apiKey,
          "Content-Type": "application/json",
        },
      });

      if (response.data && response.data.length > 0) {
        // Randomly select a quote from the response array
        const randomIndex = Math.floor(Math.random() * response.data.length);
        const quote = response.data[randomIndex];

        // Extract quote content and author from the selected quote object
        const { quote: quoteContent, author: quoteAuthor } = quote;

        // Update state with the extracted quote content and author
        setContent(quoteContent);
        setAuthor(quoteAuthor);
      } else {
        console.log("No quotes found for the specified category");
      }
    } catch (error) {
      console.error("Error fetching quote:", error);
    }
  }, []); // useCallback dependencies (empty array means no dependencies)

  useEffect(() => {
    // Fetch a new quote by category when component mounts (initial render)
    fetchQuoteByCategory("inspirational");
  }, [fetchQuoteByCategory]); // useEffect dependencies (only fetchQuoteByCategory)

  return (
    <>
      <button
        onClick={() => fetchQuoteByCategory("inspirational")}
        className="inspired"
        style={{
          border: "none",
          borderRadius: "5px",
          background: "black",
          color: "white",
          padding: "5px",
          display: "none",
        }}
      >
        Feeling Inspired?
      </button>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "3rem",
        }}
      >
        <div
          style={{ textAlign: "center", maxWidth: "380px", margin: "0 auto" }}
          className="quotes"
        >
          <h6>{content}</h6>
          <h6>-{author}</h6>
        </div>
      </div>
    </>
  );
};

export default Quote;
