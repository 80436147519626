import React from "react";
import { Link } from "react-router-dom";
import { FaRegHandPointLeft } from "react-icons/fa";

const NotFound = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "chocolate",
            display: "flex",
            lineHeight: "1",
          }}
        >
          <FaRegHandPointLeft
            style={{ color: "chocolate", marginRight: "5px" }}
          />
          Go Back
        </Link>
      </div>
      <div>
        <h1>404 - Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    </>
  );
};

export default NotFound;
