import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Quote from "../components/Quote";
import Email from "../components/Email";
import Advertise from "../components/Advertise";
import Subscribe from "../components/Subscribe";
import { Helmet } from "react-helmet-async";
import Meta from "../components/Meta";

import { motion } from "framer-motion";

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const HomeScreen = () => {
  const location = useLocation();

  const canonicalUrl = `https://www.jrome-studios.com${location.pathname}`;

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 2000); // 2 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Meta title="Welcome to J.rome Studios by J.rome LLC" />
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
        className="intro"
      >
        <motion.h1
          variants={itemVariants}
          className="style"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          Style, Capture, Create
        </motion.h1>
        <motion.h2 variants={itemVariants} style={{ textAlign: "center" }}>
          Your Source for Fashion, Photos, and Digital Design!
        </motion.h2>
        <motion.p
          variants={itemVariants}
          className="quotes welcome"
          style={{
            textAlign: "center",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          Welcome to J.rome, where style meets creativity. We specialize in
          fashion-forward apparel, captivating photography, dynamic graphic
          design, and innovative web development solutions.
        </motion.p>
      </motion.div>

      <hr style={{ margin: "5rem 0 5rem 0" }} />
      <Advertise />
      <hr style={{ marginTop: "4rem" }} />

      <Email />
      <Quote />
      <Subscribe show={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default HomeScreen;
