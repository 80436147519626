import React, { useState } from "react";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { HiOutlineMailOpen } from "react-icons/hi";
import { MdOutlinePhoneIphone } from "react-icons/md";
import { Bounce, ToastContainer, toast } from "react-toastify";

// Define validation schema with Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});

const Email = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = { name, email, message };

    // Validate form data with Yup
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        const serviceId = "service_aqwvsb2";
        const templateId = "template_5byees7";
        const publicKey = "XkmZRXimJncn-Bm3z";

        const templateParams = {
          name: name,
          email: email,
          message: message,
        };

        emailjs
          .send(serviceId, templateId, templateParams, publicKey)
          .then(
            () => {
              toast.success("Thank you for your message");
              setName("");
              setEmail("");
              setMessage("");
            },
            () => {
              toast.error("Failed to send message. Please try again.");
            }
          )
          .catch((error) => {
            console.error("Error sending email:", error);
          });
      })
      .catch((validationErrors) => {
        const errorMessages = {};
        validationErrors.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        setErrors(errorMessages);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="form">
        <h1 className="contact__jrome">Contact Us</h1>
        <h6
          className="quotes"
          style={{
            fontSize: "15px",
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
            width: "80%",
            margin: "0 auto",
          }}
        >
          Inquire about
          <a href="/services">services</a> you may need or any design you'd like
          for your apparel.
        </h6>
        <div className="quotes">
          <p type="Name:">
            <input
              className="quotes"
              placeholder="Write your name here.."
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <div className="error-message">{errors.name}</div>}
          </p>
          <p type="Email:">
            <input
              className="quotes"
              placeholder="Let us know how to contact you back.."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && (
              <div className="error-message">{errors.email}</div>
            )}
          </p>
          <p type="Message:">
            <input
              className="text quotes"
              style={{ resize: "none" }}
              placeholder="Request an invoice.."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {errors.message && (
              <div className="error-message">{errors.message}</div>
            )}
          </p>
        </div>
        <button className="message quotes" type="submit">
          Send Message
        </button>
        <div className="hit__me quotes">
          <div style={{ marginRight: "5px" }}>
            <MdOutlinePhoneIphone />
            313-474-1286
          </div>
          <div className="quotes">
            <HiOutlineMailOpen style={{ margin: "3px 3px" }} />
            jromestudios1@gmail.com
          </div>
        </div>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </div>
  );
};

export default Email;
