import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Timer from "./Timer";

import { motion } from "framer-motion";
import "../assests/index.css";

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const Advertise = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };

  const handleOverlayClick = () => {
    setSelectedImage(null);
  };

  // const fam = "https://i.ibb.co/4ZjmXZP/juneteenth-fam-colors.png";
  // const jromePair = "https://i.ibb.co/jJCQjsd/j-Rome-Pair.png";
  const jromePair = "https://i.postimg.cc/JzjMLfbP/j-Rome-Pair.png";
  // const jromeViews = "https://i.ibb.co/br7kJNf/j-Rome-Top-Bottom.png";
  const jromeViews = "https://i.postimg.cc/SNBkHSzn/j-Rome-Top-Bottom.png";
  const jromeDetail = "https://i.ibb.co/h8qgK0p/shoes-detail-jrome.png";
  // const jromePairTwo = "https://i.ibb.co/jhQc9pR/j-Rome-Side.png";
  const jromePairTwo = "https://i.postimg.cc/BQLJhLtW/j-Rome-Side.png";

  // const targetDate = "2024-06-01T00:00:00";

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    overlayImage: {
      maxWidth: "90%",
      maxHeight: "90%",
      borderRadius: "10px",
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
    >
      {/* <h3
        className="quotes"
        style={{ textAlign: "center", textTransform: "uppercase" }}
      >
        Celebrate{" "}
        <a
          style={{ color: "black" }}
          href="https://www.jrome-studios.com/product/66436c354e144d290312b43a"
        >
          Juneteenth
        </a>{" "}
        in style
      </h3>

      <div
        style={{
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img className="juneteenth" src={fam} alt="Juneteenth" />
      </div>
      <h6 style={{ margin: "3px auto 3rem", textAlign: "center" }}>
        <a
          style={{ color: "black" }}
          href="https://nmaahc.si.edu/juneteenth"
          target="_blank"
          rel="noopener noreferrer"
        >
          History
        </a>
      </h6> */}
      {/* <div
        className="shirts"
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
          margin: "0 auto 3rem",
        }}
      >
        <img
          style={{ width: "300px", borderRadius: "5px" }}
          src="https://i.ibb.co/WkDQCn7/IMG-4809.jpg"
          alt="juneteenth shirt"
        />
        <img
          style={{ width: "300px", borderRadius: "5px" }}
          src="https://i.ibb.co/xDZrWHw/IMG-4813.jpg"
          alt="juneteenth shirt"
        />
      </div> */}
      <Container
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Row>
          <Col sm={8}>
            <motion.h2 variants={itemVariants} style={{ textAlign: "center" }}>
              Discover The New "Jrome Essentials"
            </motion.h2>
            <motion.h4
              variants={itemVariants}
              style={{
                textTransform: "uppercase",
                marginTop: "8px",
                textAlign: "center",
              }}
            >
              <strong>
                {" "}
                <a
                  href="https://www.aliveshoes.com/jrome"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#39FF14", textDecoration: "none" }}
                >
                  out now <br /> follow this link
                </a>{" "}
              </strong>
            </motion.h4>
            <motion.p
              variants={itemVariants}
              className="quotes"
              style={{
                textAlign: "center",
                width: "70%",
                margin: "0 auto 1.5rem",
              }}
            >
              J'rome is a unique limited edition shoe concept handcrafted in
              Italy. Once you buy a pair, the custom shoes are manufactured and
              shipped to you.
            </motion.p>
            <motion.div variants={itemVariants}>
              {/* <Timer targetDate={targetDate} /> */}
            </motion.div>
          </Col>
          <Col sm={4}>
            <motion.div
              variants={itemVariants}
              style={{
                backgroundColor: "white",
                padding: "10px",
                border: "1px solid black",
                borderRadius: "5px",
                // width: "300px",
              }}
            >
              <h2 style={{ textAlign: "center" }}>Shoe Details</h2>
              <div className="quotes" style={{ marginLeft: "8px" }}>
                <h6>
                  <strong>Shoe type:</strong> Unisex sneakers
                </h6>
                <h6>
                  <strong>Technical name:</strong> Sporty high top
                </h6>
                <h6>
                  <strong>Main materials:</strong> Lamb leather, Mouton leather
                </h6>
                <h6>
                  <strong>Design date:</strong> April 20, 2023
                </h6>
                <h6>
                  <strong>Designed by:</strong> Reginald Dixon
                </h6>
                <h6>
                  <strong>Production type:</strong> Made in Italy
                </h6>
              </div>
            </motion.div>
          </Col>
        </Row>
        <Row>
          <Col sm style={{ display: "flex", justifyContent: "center" }}>
            <motion.img
              variants={itemVariants}
              style={{ width: "200px", height: "auto", cursor: "pointer" }}
              src={jromePair}
              alt="jromePair"
              onClick={() => handleImageClick(jromePair)}
            />
          </Col>
          <Col sm style={{ display: "flex", justifyContent: "center" }}>
            <motion.img
              variants={itemVariants}
              style={{ width: "200px", height: "auto", cursor: "pointer" }}
              src={jromeViews}
              alt="jromeViews"
              onClick={() => handleImageClick(jromeViews)}
            />
          </Col>
          <Col
            sm
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "8px",
            }}
          >
            <motion.img
              variants={itemVariants}
              style={{
                width: "auto",
                height: "150px",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              src={jromeDetail}
              alt="jromeDetail"
              onClick={() => handleImageClick(jromeDetail)}
            />
          </Col>
          <Col sm style={{ display: "flex", justifyContent: "center" }}>
            <motion.img
              variants={itemVariants}
              style={{ width: "200px", height: "auto", cursor: "pointer" }}
              src={jromePairTwo}
              alt="jromePairTwo"
              onClick={() => handleImageClick(jromePairTwo)}
            />
          </Col>
        </Row>
      </Container>
      {selectedImage && (
        <div style={styles.overlay} onClick={handleOverlayClick}>
          <img src={selectedImage} alt="Selected" style={styles.overlayImage} />
        </div>
      )}
      <div className="quotes" style={{ textAlign: "center" }}>
        <p>-or-</p>
        <a
          style={{
            textDecoration: "none",
            color: "black",
            textAlign: "center",
          }}
          href="/shop"
        >
          <button
            className="goShirt"
            style={{
              padding: "6px 14px",
              borderRadius: "6px",
              border: "none",
              background: "black",
              boxShadow:
                "0px 0.5px 1px rgba(0, 0, 0, 0.1), inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.12",
              color: "#DFDEDF",
              userSelect: "none",
              touchAction: "manipulation",
            }}
          >
            Shop for your Custom T-Shirt <br />
            here
          </button>
        </a>
      </div>
    </motion.div>
  );
};

export default Advertise;
