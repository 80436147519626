import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useGetProductsQuery } from "../slices/productsApiSlice";
import Product from "../components/Product";
import Message from "../components/Message";
import Paginate from "../components/Paginate";
import { FaRegHandPointLeft } from "react-icons/fa";

const ShopScreen = () => {
  const jromeTag = "https://i.ibb.co/bXmXnZ1/jrome-large-tag-shirt.png";

  const { pageNumber, keyword } = useParams();
  const { data, isLoading, error } = useGetProductsQuery({
    keyword,
    pageNumber,
  });

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };

  const handleOverlayClick = () => {
    setSelectedImage(null);
  };

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    overlayImage: {
      maxWidth: "90%",
      maxHeight: "90%",
      borderRadius: "10px",
    },
  };

  return (
    <>
      <div>
        {keyword && (
          <Link
            to="/home"
            style={{
              textDecoration: "none",
              color: "chocolate",
              display: "flex",
              lineHeight: "1",
            }}
          >
            <FaRegHandPointLeft
              style={{ color: "chocolate", marginRight: "5px" }}
            />
            Go Back
          </Link>
        )}
        <hr style={{ marginBottom: "5rem" }} />

        {isLoading ? (
          <h2>Loading...</h2>
        ) : error ? (
          <Message variant="danger">
            {error?.data?.message || error.error}
          </Message>
        ) : (
          <>
            <h1
              style={{
                marginTop: "1rem",
                letterSpacing: "1px",
                textAlign: "center",
              }}
            >
              <strong>
                T-Shirt Designs <sup>&</sup> Apparel
              </strong>
            </h1>
            <br />
            <br />
            <Row>
              {data.products.map((product) => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                  <Product product={product} />
                </Col>
              ))}
            </Row>
            <Paginate
              pages={data.pages}
              page={data.page}
              keyword={keyword ? keyword : ""}
            />
            <br />
          </>
        )}
      </div>
      <hr />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "6rem",
        }}
      >
        <h1>Intricate Details</h1>
        <img
          style={{ width: "300px", borderRadius: "5px" }}
          src={jromeTag}
          alt="jrome tag"
          onClick={() => handleImageClick(jromeTag)}
        />
        {selectedImage && (
          <div style={styles.overlay} onClick={handleOverlayClick}>
            <img
              src={selectedImage}
              alt="Selected"
              style={styles.overlayImage}
            />
          </div>
        )}
        <br />
        <h4 className="quotes">100% Cotton Quality Shirts</h4>
      </div>
    </>
  );
};

export default ShopScreen;
