import React from "react";
import { Link } from "react-router-dom";
import { FaRegHandPointLeft } from "react-icons/fa";
import { Helmet } from "react-helmet-async";
import Meta from "../components/Meta";

import { motion } from "framer-motion";

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const CompanyScreen = () => {
  return (
    <>
      <Meta title="J.rome Quality Assurance" />
      <Helmet>
        <link rel="canonical " href="/company" />
      </Helmet>
      <div
        style={{
          display: "flex",
        }}
      >
        <Link
          to="/home"
          style={{
            textDecoration: "none",
            color: "chocolate",
            display: "flex",
            lineHeight: "1",
          }}
        >
          <FaRegHandPointLeft
            style={{ color: "chocolate", marginRight: "5px" }}
          />
          Go Back
        </Link>
      </div>
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <motion.div
          className="company"
          style={{ textAlign: "center", margin: "3rem 0 3rem 0" }}
          variants={itemVariants}
        >
          <h1>J.rome the Brand</h1>
        </motion.div>
        <motion.div variants={itemVariants}>
          <h5 className="quotes" style={{ textAlign: "center" }}>
            Welcome to J.rome, where we curate the finest shirts from top
            wholesalers for quality and comfort. Our commitment to excellence
            means crafting premium 100% cotton shirts that embody natural
            elegance and unparalleled benefits for your wardrobe. At J.rome, we
            prioritize your comfort and style. Experience the luxurious feel of
            our cotton shirts, offering breathability and softness with every
            wear. Stay cool and dry year-round with exceptional moisture
            absorption. Discover comfort redefined with J.rome.
          </h5>
        </motion.div>
        <br />
        <motion.div variants={itemVariants}>
          <h5
            className="quotes"
            style={{ textAlign: "center", marginBottom: "1rem" }}
          >
            <strong>
              Why cotton? Here are the key benefits that make our shirts stand
              out:
            </strong>
          </h5>
          <ol className="quotes" style={{ letterSpacing: "1px" }}>
            <li>
              <strong>Breathability:</strong> Cotton is highly breathable,
              allowing air to circulate freely and keep your body cool and
              comfortable throughout the day.
            </li>
            <li>
              <strong>Softness:</strong> Enjoy the gentle touch of cotton
              against your skin. Our shirts are crafted to be incredibly soft,
              making them ideal for daily wear.
            </li>
            <li>
              <strong>Moisture Absorption:</strong> Cotton absorbs moisture
              efficiently, keeping you dry by wicking away sweat and preventing
              that clammy feeling.
            </li>
            <li>
              <strong>Durability:</strong> Our 100% cotton shirts are durable
              and long-lasting, maintaining their quality and shape wash after
              wash.
            </li>
            <li>
              <strong>Versatility:</strong> From casual outings to business
              settings, cotton shirts from J.rome effortlessly transition
              between different occasions, offering timeless style and
              versatility.
            </li>
          </ol>
          <div>
            <h5 className="quotes" style={{ textAlign: "center" }}>
              Enhance your wardrobe with J.rome's selection of premium 100%
              cotton shirts. Each piece is meticulously crafted for comfort,
              style, and lasting quality. Discover our collection and indulge in
              the luxury of pure cotton craftsmanship.
            </h5>
            <h5
              className="quotes"
              style={{ textAlign: "center", margin: "3rem 0 4rem 0" }}
            >
              Thank you for shopping!
            </h5>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default CompanyScreen;
