import { Alert } from "react-bootstrap";

const Message = ({ variant, children }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Alert variant={variant} style={{ width: "50%", textAlign: "center" }}>
        {children}
      </Alert>
    </div>
  );
};

Message.defaultProps = {
  variant: "info",
};

export default Message;
