import { useState } from "react";
import graphicData from "../constants/graphicConstant";
import { Link } from "react-router-dom";

export default function GraphScreen() {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };

  const handleOverlayClick = () => {
    setSelectedImage(null);
  };

  const styles = {
    galleryContainer: {
      display: "grid",
      gridTemplateColumns: "1fr",
      justifyItems: "center",
      alignItems: "center",
      gap: "20px",
      padding: "20px",
    },
    graphicCube: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      backgroundColor: "#f0f0f0",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      width: "60%",
    },
    graph: {
      maxWidth: "100%",
      height: "auto",
      borderRadius: "5px",
      cursor: "pointer",
    },
    quotes: {
      marginTop: "10px",
      fontSize: "1rem",
      textAlign: "center",
      color: "#333",
      textTransform: "uppercase",
    },
    header: {
      textAlign: "center",
      fontSize: "3rem",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    overlayImage: {
      maxWidth: "90%",
      maxHeight: "90%",
      borderRadius: "10px",
    },
  };

  const mediaQueryStyles = `
    @media (min-width: 768px) {
      .gallery-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    @media (min-width: 1024px) {
      .gallery-container {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  `;

  return (
    <>
      <style>{mediaQueryStyles}</style>
      <br />
      <div className="quotes">
        <Link to="/services" style={{ textDecoration: "none", color: "black" }}>
          Services
        </Link>
        <br />
        <Link to="/photo" style={{ textDecoration: "none", color: "black" }}>
          Photo Gallery
        </Link>
        <br />
        <Link to="/web" style={{ textDecoration: "none", color: "black" }}>
          Web Gallery
        </Link>
      </div>
      <hr />
      <br />
      <div>
        <h1 style={styles.header}>Graphics</h1>
        <br />
      </div>
      <div className="gallery-container" style={styles.galleryContainer}>
        {graphicData.map((item) => (
          <div key={item.id} style={styles.graphicCube}>
            <img
              style={styles.graph}
              src={item.img}
              alt={item.text}
              loading="lazy"
              onClick={() => handleImageClick(item.img)}
            />
            <p className="quotes" style={styles.quotes}>
              {item.text}
            </p>
          </div>
        ))}
      </div>
      {selectedImage && (
        <div style={styles.overlay} onClick={handleOverlayClick}>
          <img src={selectedImage} alt="Selected" style={styles.overlayImage} />
        </div>
      )}
      <br />
      <br />
    </>
  );
}
