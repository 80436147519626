import { Container, Row, Col } from "react-bootstrap";

const FormContainer = ({ children }) => {
  return (
    <Container
      style={{
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
        backgroundColor: "#FAF9F6",
        padding: "1rem 0 1rem 0",
        width: "900px",
      }}
    >
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default FormContainer;
