import React, { useState } from "react";
import { motion } from "framer-motion";

function ServiceGraphics({ text, index }) {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseOverlay = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <br />
      <br />
      <h1 style={{ textAlign: "center", fontSize: "5rem" }}>
        Latest Creations
      </h1>
      <p
        style={{
          textAlign: "center",
          position: "relative",
          top: "7rem",
          color: "gray",
          opacity: "0.5",
        }}
      >
        tap to enlarge
      </p>
      <motion.div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "500px",
          margin: "6rem 0 0 0",
          overflow: "hidden",
          gap: "3px",
        }}
        className="serviceGraphic"
        initial={{
          opacity: 0,
          y: index % 2 === 0 ? 50 : -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 1,
          },
        }}
        viewport={{ once: true }}
      >
        <img
          src="https://i.ibb.co/kKsfKn4/marble-male-statue-blak-groth.png"
          alt="blak-groth"
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "contain",
            cursor: "pointer",
          }}
          className="serviceGraphicLeft"
          onClick={() =>
            handleClick(
              "https://i.ibb.co/kKsfKn4/marble-male-statue-blak-groth.png"
            )
          }
        />

        <img
          src="https://i.ibb.co/bQtN94n/visual-identity-mockup.png"
          alt="visual-identity-mock-up"
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "contain",
            cursor: "pointer",
          }}
          onClick={() =>
            handleClick("https://i.ibb.co/bQtN94n/visual-identity-mockup.png")
          }
        />
        <img
          src="https://i.ibb.co/K7g6hRS/marble-statue-jrome.png"
          alt="jrome"
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "contain",
            cursor: "pointer",
          }}
          className="serviceGraphicRight"
          onClick={() =>
            handleClick("https://i.ibb.co/K7g6hRS/marble-statue-jrome.png")
          }
        />
      </motion.div>
      {selectedImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={handleCloseOverlay}
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              maxHeight: "80%",
              maxWidth: "80%",
              objectFit: "contain",
            }}
          />
        </div>
      )}
      {selectedImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor:
              selectedImage === "https://i.ibb.co/4sdynPk/love-framed.png"
                ? "white"
                : "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={handleCloseOverlay}
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              maxHeight: "80%",
              maxWidth: "80%",
              objectFit: "contain",
            }}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0 auto 15rem",
          cursor: "pointer",
        }}
        onClick={() =>
          handleClick("https://i.ibb.co/mtN28qn/love-inside-red.png")
        }
      >
        <img
          className="love_logo"
          src="https://i.ibb.co/4sdynPk/love-framed.png"
          alt="Love Inside"
          style={{
            maxHeight: "35%",
            maxWidth: "35%",
            objectFit: "contain",
          }}
        />
      </div>

      <div
        className="three_shirts"
        style={{
          height: "400px",
          display: "flex",
          justifyContent: "center",
          margin: "0 auto ",
        }}
      >
        <div>
          <img
            src="https://i.ibb.co/mCzQGxm/Cream-loyal-carolina.png"
            alt="loyal"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div>
          <img
            src="https://i.ibb.co/R6HB1KH/White-Front-not-me-mint-flmgo.png"
            alt="if not me then who?"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div>
          <img
            src="https://i.ibb.co/1ZXwxXX/White-Front-Ghost-og-purp.png"
            alt="ghostwriter"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "cover",
            }}
          />
        </div>
      </div>
      <br />
      <br />
    </>
  );
}

export default ServiceGraphics;
