import React from "react";
import { Offcanvas } from "react-bootstrap";

const CustomModalAdvice = ({ show, handleClose, listItems }) => {
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header
          closeButton
          style={{ backgroundColor: "black" }}
        ></Offcanvas.Header>
        <Offcanvas.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "black",
          }}
        >
          <span
            style={{
              marginBottom: "20px",
              textAlign: "center",
              color: "white",
              fontStyle: "italic",
              fontSize: "20px",
            }}
            className="quotes"
          >
            What you should get started on...
          </span>
          <div
            className="quotes"
            style={{
              textAlign: "center",
              padding: 0,
              fontSize: "18px",
              color: "green",
            }}
          >
            {listItems.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
          <span
            style={{
              margin: "20px 0 8px 0",
              textAlign: "center",
              color: "white",
              fontStyle: "italic",
              fontSize: "20px",
            }}
          >
            Let me help you get it together!
          </span>

          <h2 style={{ color: "white" }}>313-474-1286</h2>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CustomModalAdvice;
