import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Badge, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLogoutMutation } from "../slices/usersApiSlice";
import { logout } from "../slices/authSlice";
// import SearchBox from "./SearchBox";
import { FaUsers } from "react-icons/fa";
import { TiShoppingCart } from "react-icons/ti";
import { resetCart } from "../slices/cartSlice";

import { motion } from "framer-motion";

import "../assests/index.css";

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const Header = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      dispatch(resetCart());
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <motion.header
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
    >
      <br />
      <Navbar
        expand="md"
        collapseOnSelect
        style={{
          color: "white",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <LinkContainer to="/home">
            <Navbar.Brand className="logo">
              <motion.img
                variants={itemVariants}
                whileTap={{ scale: 0.85 }}
                className="logo"
                src="https://i.postimg.cc/RFMwfNfy/jrome-stu-logo.png"
                alt="jrome brand logo"
              ></motion.img>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggle" />
          <Navbar.Collapse id="basic-navbar-nav" className="no-border">
            <Nav className="ms-auto">
              {/* <SearchBox /> */}
              <LinkContainer to="/home">
                <Nav.Link>
                  <motion.div
                    variants={itemVariants}
                    style={{ position: "relative", top: "2px" }}
                  >
                    Home
                  </motion.div>
                </Nav.Link>
              </LinkContainer>
              <motion.div
                variants={itemVariants}
                style={{ position: "relative", top: "2px" }}
              >
                <NavDropdown title="Browse" id="browser">
                  <LinkContainer to="/shop">
                    <NavDropdown.Item>Shop</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/services">
                    <NavDropdown.Item>Services</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/about">
                    <NavDropdown.Item>About</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              </motion.div>
              <LinkContainer
                to="/cart"
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Nav.Link>
                  <motion.div
                    variants={itemVariants}
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    <TiShoppingCart />
                    Cart
                    {cartItems.length > 0 && (
                      <Badge pill bg="info" style={{ marginLeft: "3px" }}>
                        {cartItems.reduce((a, c) => a + c.qty, 0)}
                      </Badge>
                    )}
                  </motion.div>
                </Nav.Link>
              </LinkContainer>
              {userInfo ? (
                <div style={{ position: "relative", top: "2px" }}>
                  <NavDropdown title={userInfo.name} id="username">
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              ) : (
                <LinkContainer
                  to="/login"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Nav.Link>
                    <motion.div
                      variants={itemVariants}
                      style={{ position: "relative", top: "2px" }}
                    >
                      <FaUsers />
                      Sign In
                    </motion.div>
                  </Nav.Link>
                </LinkContainer>
              )}
              <div style={{ position: "relative", top: "2px" }}>
                {userInfo && userInfo.isAdmin && (
                  <NavDropdown title="Admin" id="adminmenu">
                    <LinkContainer to="/admin/productlist">
                      <NavDropdown.Item>Products</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/userlist">
                      <NavDropdown.Item>User</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/admin/orderlist">
                      <NavDropdown.Item>Orders</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </motion.header>
  );
};

export default Header;
