import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaRegHandPointLeft } from "react-icons/fa";
import Meta from "../components/Meta";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Rating from "../components/Rating";
import Message from "../components/Message";
import {
  useGetProductDetailsQuery,
  useCreateReviewMutation,
} from "../slices/productsApiSlice";
import { addToCart } from "../slices/cartSlice";

import { TiArrowRightOutline } from "react-icons/ti";
import { Helmet } from "react-helmet-async";

const ProductScreen = () => {
  const { id: productId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [qty, setQty] = useState(1);
  const [selectedSize, setSelectedSize] = useState("");
  const [color, setColor] = useState("");
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
  };

  const sizes = ["Small", "Medium", "Large", "X-Large"];

  const handleClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const {
    data: product,
    isLoading,
    refetch,
    error,
  } = useGetProductDetailsQuery(productId);

  const [createReview, { isLoading: loadingReview }] =
    useCreateReviewMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const addToCartHandler = () => {
    dispatch(addToCart({ ...product, qty, selectedSize, color }));
    navigate("/cart");
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await createReview({ productId, rating, comment }).unwrap();
      refetch();
      toast.success("Review Submitted");
      setRating(0);
      setComment("");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Meta title="J'rome Collections" />
      <Helmet>
        <link rel="canonical " href="/product" />
      </Helmet>
      <Link
        to="/shop"
        style={{
          textDecoration: "none",
          color: "chocolate",
          display: "flex",
          lineHeight: "1",
          marginBottom: "5rem",
        }}
      >
        <FaRegHandPointLeft
          style={{ color: "chocolate", marginRight: "5px" }}
        />
        Go Back
      </Link>

      {isLoading ? (
        <h2>Loading...</h2>
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Row className="product_screen">
            <Col md={5} style={{ zIndex: "9999" }}>
              <div className="image_contain" style={{ aspectRatio: "1/1" }}>
                <Image
                  src={product.image}
                  alt={product.name}
                  loading=""
                  fluid
                  className="product__image"
                  onClick={() => handleClick(product.image)}
                  style={{
                    borderRadius: "5px",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <span style={{ color: "gray", opacity: "0.4" }}>
                tap image to enlarge
              </span>
              {selectedImage && (
                <div
                  className="overlay"
                  onClick={() => setSelectedImage(null)}
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "rgba(0, 0, 0, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "99999",
                  }}
                >
                  <img
                    src={selectedImage}
                    alt="jrome"
                    style={{
                      maxWidth: "90%",
                      maxHeight: "90%",
                      borderRadius: "5px",
                    }}
                  />
                </div>
              )}
            </Col>
            <Col md={4}>
              <ListGroup
                variant="flush"
                style={{
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                  borderRadius: "5px",
                }}
              >
                <ListGroup.Item>
                  <h3>{product.name}</h3>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={product.rating}
                    text={`${product.numReviews} reviews`}
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Price:</strong> ${product.price}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Description:</strong> {product.description}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Category:</strong> {product.category}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={3}>
              <Card style={{ boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)" }}>
                <ListGroup>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <strong>Price:</strong>
                      </Col>
                      <Col>
                        <strong>${product.price}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <strong>Status:</strong>
                      </Col>
                      <Col>
                        <strong>
                          {product.countInStock > 0
                            ? "In Stock"
                            : "Out of Stock"}
                        </strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {product.countInStock > 0 && (
                    <ListGroup.Item>
                      <Row style={{ display: "flex", alignItems: "center" }}>
                        <Col>
                          <strong>Quantity:</strong>
                        </Col>
                        <Col>
                          <Form.Control
                            as="select"
                            value={qty}
                            onChange={(e) => setQty(Number(e.target.value))}
                          >
                            {[...Array(product.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                  {product.countInStock > 0 && (
                    <ListGroup.Item>
                      <Row style={{ display: "flex", alignItems: "center" }}>
                        <Col>
                          <strong>Size:</strong>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <TiArrowRightOutline />
                          <Form.Control
                            as="select"
                            value={selectedSize}
                            onChange={handleSizeChange}
                          >
                            <option value=""></option>
                            {sizes.map((size) => (
                              <option key={size} value={size}>
                                {size}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                  {product.countInStock > 0 && (
                    <ListGroup.Item>
                      <Row style={{ display: "flex", alignItems: "center" }}>
                        <Col>
                          <strong>Color:</strong>
                        </Col>
                        <Col
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <TiArrowRightOutline />

                          <Form.Control
                            as="select"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <option value=""></option>
                            <option value="White">White</option>
                            <option value="Red">Red</option>
                            <option value="Blue">Blue</option>
                            <option value="Green">Green</option>
                            <option value="Yellow">Yellow</option>
                            <option value="Black">Black</option>
                            <option value="Grey">Grey</option>
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <span>Orders over $100 have free shipping.</span>
                  </div>
                  <ListGroup.Item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      // className="btn-block"
                      type="button"
                      disabled={product.countInStock === 0}
                      onClick={addToCartHandler}
                      className="update"
                      style={{
                        backgroundColor: "black",
                        border: "none",
                      }}
                    >
                      Add To Cart
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className="review">
            <Col md={6}>
              <h2>Reviews</h2>
              {product.reviews.length === 0 && <Message>No Reviews</Message>}
              <ListGroup variant="flush">
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item
                  style={{
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                    backgroundColor: "#FAF9F6",
                    marginTop: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  <h2 style={{ marginTop: "1rem" }}>Write a Review</h2>
                  {loadingReview && <h2>Loading...</h2>}
                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId="rating" className="my-2">
                        <Form.Label>
                          <strong>Rating:</strong>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={rating}
                          onChange={(e) => setRating(Number(e.target.value))}
                          style={{ width: "" }}
                        >
                          <option value="">Select</option>
                          <option value="1">1 - Poor</option>
                          <option value="2">2 - Fair</option>
                          <option value="3">3 - Good</option>
                          <option value="4">4 - Very Good</option>
                          <option value="5">5 - Excellent</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="comment" className="my-2">
                        <Form.Label>
                          <strong>Comment:</strong>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          row="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        disabled={loadingReview}
                        type="submit"
                        className="update"
                        style={{
                          backgroundColor: "var(--cyan-700)",
                          border: "none",
                          marginBottom: "1rem",
                        }}
                      >
                        Submit
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      Please <Link to="/login">sign in</Link> to write a review
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProductScreen;
