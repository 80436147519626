import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Rating from "./Rating";

const Product = ({ product }) => {
  return (
    <div className="product-card-container">
      <div className="product-card">
        <Link to={`/product/${product._id}`}>
          <div className="product-image-container">
            <Card.Img
              src={product.image}
              alt={product.name}
              variant="top"
              className="product-img"
            />
          </div>
        </Link>

        <Card.Body>
          <Link
            to={`/product/${product._id}`}
            style={{ textDecoration: "none" }}
          >
            <Card.Title
              as="div"
              style={{
                textAlign: "center",
                color: "black",
              }}
            >
              <strong>{product.name}</strong>
            </Card.Title>
          </Link>

          {/* <Card.Text as="div">
            <Rating
              value={product.rating}
              text={`${product.numReviews} reviews`}
            />
          </Card.Text> */}

          <Card.Text as="h4" style={{ textAlign: "center" }}>
            ${product.price}
          </Card.Text>
        </Card.Body>
      </div>
    </div>
  );
};

export default Product;
