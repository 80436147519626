import { Swiper, SwiperSlide } from "swiper/react";
import { GoDesktopDownload } from "react-icons/go";
import webData from "../constants/webConstant";
import { Link } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper/modules";

const WebGallery = () => {
  return (
    <>
      <br />
      <div className="quotes">
        <Link to="/services" style={{ textDecoration: "none", color: "black" }}>
          Services
        </Link>{" "}
        <br />
        <Link to="/graphic" style={{ textDecoration: "none", color: "black" }}>
          Graphic Gallery
        </Link>
        <br />
        <Link to="/photo" style={{ textDecoration: "none", color: "black" }}>
          Photo Gallery
        </Link>
      </div>
      <hr />
      <br />
      <div>
        <h1 style={{ textAlign: "center", fontSize: "3rem" }}>Web Gallery</h1>
      </div>
      <br />
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {webData.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="graphic__cube">
              <img
                src={item.img}
                alt={item.text}
                loading=""
                style={{
                  maxWidth: "50%",
                  maxHeight: "50%",
                  margin: "auto",
                  display: "block",
                  borderRadius: "5px",
                  aspectRatio: "1/1",
                }}
              />
            </div>
            <p
              className="quotes"
              style={{
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {item.text}
            </p>
          </SwiperSlide>
        ))}
      </Swiper>
      <br />
      <br />
      <div>
        <h1 style={{ textAlign: "center" }}>Check out the mock site below:</h1>
        <p className="quotes width" style={{ textAlign: "center" }}>
          The website below emphasises its best feature of navigation control
          functionality for mobile and desktop view. <br /> Take a look.
        </p>
        <a
          style={{ textDecoration: "none", color: "black" }}
          href="https://jrome-landing-nav-example.netlify.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>Mock Landing Page</strong>
          <GoDesktopDownload style={{ marginLeft: "5px" }} />
        </a>
      </div>
      <div style={{ display: "flex" }}>
        <iframe
          src="https://jrome-landing-nav-example.netlify.app/"
          width="100%"
          height="400"
          title="Jrome Landing Page"
          style={{
            border: "none",
            borderRadius: "5px",
          }}
        ></iframe>
      </div>
      <br />
      <br />
    </>
  );
};

export default WebGallery;
