import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import { IoTrash } from "react-icons/io5";
import Message from "../components/Message";
import { FaRegHandPointLeft } from "react-icons/fa";
import { addToCart, removeFromCart } from "../slices/cartSlice";
import Meta from "../components/Meta";

const CartScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const addtoCartHandler = (product, qty) => {
    dispatch(addToCart({ ...product, qty }));
  };

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    navigate("/login?redirect=/shipping");
  };

  return (
    <>
      <Meta title="J.rome Shopping Cart" />
      <Helmet>
        <link rel="canonical " href="/cart" />
      </Helmet>
      <Row className="justify-content-center">
        <Col md={8}>
          <h1 style={{ marginBottom: "20px" }}>Shopping Cart</h1>
          {cartItems.length === 0 ? (
            <Message>
              Your cart is empty{" "}
              <Link
                to="/home"
                style={{
                  textDecoration: "none",
                  color: "chocolate",
                  display: "flex",
                  lineHeight: "1",
                }}
              >
                <FaRegHandPointLeft
                  style={{ color: "chocolate", marginRight: "5px" }}
                />
                Go Back
              </Link>
            </Message>
          ) : (
            <ListGroup
              variant="flush"
              style={{
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                backgroundColor: "#FAF9F6",
              }}
            >
              {cartItems.map((item) => (
                <ListGroup.Item key={item._id}>
                  <Row className="align-items-center">
                    <Col md={2}>
                      <Image
                        src={item.image}
                        alt={item.name}
                        fluid
                        rounded
                        style={{
                          boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
                          backgroundColor: "#FAF9F6",
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Link
                        to={`/product/${item._id}`}
                        style={{
                          textDecoration: "none",
                          color: "var(--cyan-700)",
                        }}
                      >
                        {item.name}
                      </Link>
                    </Col>
                    <Col md={2}>${item.price}</Col>
                    <Col md={2}>
                      <Form.Control
                        as="select"
                        value={item.qty}
                        onChange={(e) =>
                          addtoCartHandler(item, Number(e.target.value))
                        }
                      >
                        {[...Array(item.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col md={2} className="text-center">
                      {item.selectedSize}
                    </Col>
                    <Col md={1} className="text-center">
                      {item.color}
                    </Col>
                    <Col md={1}>
                      <Button
                        type="button"
                        variant="inherit"
                        style={{
                          position: "relative",
                          bottom: "9px",
                          fontSize: "2rem",
                        }}
                        onClick={() => removeFromCartHandler(item._id)}
                      >
                        <IoTrash />
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>
                  Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}
                  ) items
                </h2>
                $
                {cartItems
                  .reduce((acc, item) => acc + item.qty * item.price, 0)
                  .toFixed(2)}
              </ListGroup.Item>
              <ListGroup.Item className="text-center">
                <Button
                  type="button"
                  className="btn-block update"
                  disabled={cartItems.length === 0}
                  onClick={checkoutHandler}
                  style={{
                    backgroundColor: "black",
                    border: "none",
                  }}
                >
                  Proceed To Checkout
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CartScreen;
