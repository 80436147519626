import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";
import CustomModal from "../components/CustomModal";
import CustomModalAdvice from "../components/CustomModalAdvice";
import ServiceGraphics from "../components/ServiceGraphics";
import { Helmet } from "react-helmet-async";
import { FaRegHandPointLeft } from "react-icons/fa6";
import { FaRegHandPointRight } from "react-icons/fa6";
import { FaHandPointLeft } from "react-icons/fa6";
import { BsInfoCircle } from "react-icons/bs";

import { motion } from "framer-motion";

import {
  graphic,
  photo,
  web,
  listItems,
  listAdviceItems,
  images,
} from "../constants/serviceConstant";

// console.log(graphic.itemOne);
// console.log(listItems);

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const Services = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [showAdviceModal, setShowAdviceModal] = useState(false);

  const handleShowAdviceModal = () => {
    setShowAdviceModal(true);
  };

  const handleCloseAdviceModal = () => {
    setShowAdviceModal(false);
  };

  return (
    <>
      <Meta title="Services of J'rome" />
      <Helmet>
        <link rel="canonical " href="/services" />
      </Helmet>
      <div>
        <Link
          to="/home"
          style={{
            textDecoration: "none",
            color: "chocolate",
            display: "flex",
            lineHeight: "1",
          }}
        >
          <FaHandPointLeft style={{ color: "chocolate", marginRight: "5px" }} />
          Go Back
        </Link>
      </div>
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <motion.h2
          variants={itemVariants}
          className="myServicesTitle theServiceTitle"
          style={{
            textAlign: "center",
            fontSize: "5rem",
            backgroundColor: "black",
            padding: "20px ",
            overflow: "hidden",
            borderRadius: "3px",
          }}
        >
          My Services
        </motion.h2>
        <h2 className="company" style={{ textAlign: "center" }}>
          rebuild, revamp, and replenish any design.
        </h2>
        <h4
          style={{ textAlign: "center", marginTop: "2rem" }}
          className="quotes"
        >
          Call to Inquire | 313-474-1286
        </h4>
      </motion.div>
      <motion.div
        className="grid-container"
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <motion.h1 variants={itemVariants}>Graphics</motion.h1>
            </Accordion.Header>
            <Accordion.Body>
              <div
                style={{
                  backgroundColor: "rgb(243,229,171)",
                  borderRadius: "5px",
                  padding: "5px 0 2px 0",
                  border: "1px solid black",
                }}
              >
                {graphic.itemOne.map((item) => (
                  <h4 key={item.id}>{item.name}</h4>
                ))}
                <span
                  onClick={handleShowModal}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  more...
                </span>
                <br />
                <Link
                  className="quotes"
                  to="/graphic"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <FaRegHandPointRight style={{ margin: "1rem" }} />
                  Visit Gallery
                  <FaRegHandPointLeft style={{ margin: "1rem" }} />
                </Link>

                <CustomModal
                  show={showModal}
                  handleClose={handleCloseModal}
                  title="Graphic Design Services"
                  listItems={listItems}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <motion.h1 variants={itemVariants}>Photography</motion.h1>
            </Accordion.Header>
            <Accordion.Body>
              <div
                style={{
                  backgroundColor: "rgb(243,229,171)",
                  borderRadius: "5px",
                  padding: "5px 0 2px 0",
                  border: "1px solid black",
                }}
              >
                {photo.itemTwo.map((item) => (
                  <h4 key={item.id}>{item.name}</h4>
                ))}
                <br />

                <Link
                  className="quotes"
                  to="/photo"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <FaRegHandPointRight style={{ margin: "1rem" }} />
                  Visit Gallery
                  <FaRegHandPointLeft style={{ margin: "1rem" }} />
                </Link>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <motion.h1 variants={itemVariants}>Websites</motion.h1>
            </Accordion.Header>
            <Accordion.Body>
              <div
                style={{
                  backgroundColor: "rgb(243,229,171)",
                  borderRadius: "5px",
                  padding: "5px 0 2px 0",
                  border: "1px solid black",
                }}
              >
                {web.itemThree.map((item) => (
                  <h4 key={item.id}>{item.name}</h4>
                ))}
                <br />

                <Link
                  className="quotes"
                  to="/web"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <FaRegHandPointRight style={{ margin: "1rem" }} />
                  Visit Gallery
                  <FaRegHandPointLeft style={{ margin: "1rem" }} />
                </Link>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </motion.div>
      <div
        className="quotes"
        style={{
          textAlign: "center",
        }}
      >
        <CustomModalAdvice
          show={showAdviceModal}
          handleClose={handleCloseAdviceModal}
          title="Starting A Business Checklist"
          listItems={listAdviceItems}
          onCloseText="Close"
        />
        Tip: Want to start your own business? I have great advice.
        <span
          onClick={handleShowAdviceModal}
          style={{ cursor: "pointer", color: "blue" }}
        >
          <BsInfoCircle
            style={{
              position: "relative",
              bottom: "1px",
              color: "red",
              marginLeft: "-8px",
            }}
          />
        </span>
      </div>
      <hr />
      <p
        className="quotes"
        style={{ margin: "3rem", textAlign: "center", fontSize: "20px" }}
      >
        "Love is the most important thing in the world. Hate, we should remove
        from the dictionary."
        <br />- John Wooden
      </p>
      <hr />

      <ServiceGraphics images={images} />
    </>
  );
};

export default Services;
