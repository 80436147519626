import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";
import { register } from "swiper/element/bundle";
register();

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <main className="py-3">
        <Container>
          <Outlet />
        </Container>
      </main>

      <Footer />
      <ToastContainer position="bottom-left" theme="dark" autoClose={2000} />
    </>
  );
};

export default App;
