import React, { useState } from "react";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Make sure to import the CSS
import { useNavigate } from "react-router-dom";

// Define validation schema with Yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const UnsubscribeScreen = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = { email };

    // Validate form data with Yup
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        const serviceId = "service_aqwvsb2";
        const templateId = "template_5byees7";
        const publicKey = "XkmZRXimJncn-Bm3z";

        const templateParams = {
          email: email,
          message: "A user has requested to unsubscribe.",
        };

        emailjs
          .send(serviceId, templateId, templateParams, publicKey)
          .then(
            () => {
              toast.success("Sorry to see you go", {
                onClose: () => navigate("/"),
              });
              setEmail("");
            },
            () => {
              toast.error("Failed to unsubscribe. Please try again.");
            }
          )
          .catch((error) => {
            console.error("Error unsubscribing:", error);
          });
      })
      .catch((validationErrors) => {
        const errorMessages = {};
        validationErrors.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        setErrors(errorMessages);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div
          className="subscribe-content quotes"
          style={{ textAlign: "center" }}
        >
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: "5px" }}
          />
          {errors.email && <div className="error-message">{errors.email}</div>}
          <br />
          <div className="btn__subscribe__container">
            <button
              className="btn-subscribe"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "black",
                cursor: "pointer",
                padding: "10px 20px",
                fontSize: "16px",
                textAlign: "center", // Center text horizontally
                display: "flex", // Use flexbox
                justifyContent: "center", // Center content horizontally
                alignItems: "center", // Center content vertically
              }}
            >
              Unsubscribe
            </button>
          </div>
        </div>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </div>
  );
};

export default UnsubscribeScreen;
