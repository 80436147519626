import React from "react";
import { Link } from "react-router-dom";
import { FaRegHandPointLeft } from "react-icons/fa";
import Meta from "../components/Meta";
import { Helmet } from "react-helmet-async";

import { motion } from "framer-motion";

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const PrivacyScreen = () => {
  return (
    <>
      <Meta title="J'rome Privacy Policy" />
      <Helmet>
        <link rel="canonical " href="/privacy" />
      </Helmet>
      <div
        style={{
          display: "flex",
        }}
      >
        <Link
          to="/home"
          style={{
            textDecoration: "none",
            color: "chocolate",
            display: "flex",
            lineHeight: "1",
          }}
        >
          <FaRegHandPointLeft
            style={{ color: "chocolate", marginRight: "5px" }}
          />
          Go Back
        </Link>
      </div>
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <motion.div
          style={{ textAlign: "center", marginTop: "3rem" }}
          variants={itemVariants}
        >
          <strong>Privacy Policy</strong>
          <br />
          <br />
          <strong>
            This Privacy Policy describes how J'rome LLC ("we", "us", or "our")
            collects, uses, and discloses personal information when you use our
            ecommerce website (the "Site"). By accessing or using the Site, you
            agree to the terms of this Privacy Policy.
          </strong>
        </motion.div>
        <motion.div style={{ marginTop: "3rem" }} variants={itemVariants}>
          <strong>Information We Collect</strong>
          <div style={{ marginTop: "5px" }}>
            <strong>Login Information:</strong>
            <h6>
              When you create an account on our Site, we collect your name,
              email address, password, and any other information you provide
              during account registration.
            </h6>
          </div>
          <div>
            <strong>Subscription Information:</strong>
            <h6>
              If you subscribe to our services or newsletters, we collect your
              email address and subscription preferences.
            </h6>
          </div>
          <div>
            <strong>Credit Card Information:</strong>
            <h6>
              We want to assure you that we do not collect or store credit card
              information on our Site. All payments are securely processed
              through third-party payment processors.
            </h6>
          </div>
          <div>
            <strong>Use of Information</strong>
            <div style={{ marginTop: "5px" }}>
              <strong>
                We use the collected information for the following purposes:
              </strong>
              <ul>
                <li>To provide and maintain our ecommerce services.</li>
                <li>
                  To personalize user experience and improve customer service.
                </li>
                <li>
                  To send promotional emails and updates about our products and
                  services, with the option to unsubscribe at any time.
                </li>
              </ul>
            </div>
            <div>
              <strong>Tracking and Cookies</strong>
              <h6>
                We use cookies and similar tracking technologies to track user
                activity on our Site, including login sessions and browsing
                behavior. This helps us understand how users interact with our
                Site and improve our services.
              </h6>
            </div>
            <div>
              <strong>Age Restriction</strong>
              <h6>
                Our services are not intended for individuals under the age of
                18. We do not knowingly collect personal information from
                individuals under 18 years of age. If you are under 18, please
                do not use our Site or provide any personal information.
              </h6>
            </div>
            <div>
              <strong>Disclosure of Information</strong>
              <h6>
                We may disclose your personal information to third-party service
                providers who assist us in operating our business and providing
                services to you. These third parties are required to maintain
                the confidentiality and security of your personal information.
              </h6>
            </div>
            <div>
              <strong>Data Security</strong>
              <h6>
                We take reasonable measures to protect the security of your
                personal information and prevent unauthorized access, use, or
                disclosure.
              </h6>
            </div>
            <div>
              <strong>Your Rights</strong>
              <h6>
                You have the right to access, correct, or delete your personal
                information. You may also request to opt-out of receiving
                promotional communications from us.
              </h6>
            </div>
            <div>
              <strong>Changes to this Privacy Policy</strong>
              <h6>
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </h6>
            </div>
            <div>
              <strong>Contact Us</strong>
              <h6>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at jromestudios1@gmail.com .
              </h6>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default PrivacyScreen;
