import React from "react";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";
import { Helmet } from "react-helmet-async";

import { FaRegHandPointLeft } from "react-icons/fa";

import { motion } from "framer-motion";

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const AboutScreen = () => {
  const text = "You're In Good Company".split(" ");
  return (
    <>
      <Meta title="About J.rome" />
      <Helmet>
        <link rel="canonical " href="/about" />
      </Helmet>
      <div
        style={{
          display: "flex",
        }}
      >
        <Link
          to="/home"
          style={{
            textDecoration: "none",
            color: "chocolate",
            display: "flex",
            lineHeight: "1",
          }}
        >
          <FaRegHandPointLeft
            style={{ color: "chocolate", marginRight: "5px" }}
          />
          Go Back
        </Link>
      </div>
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
        style={{ textAlign: "center", marginTop: "5rem" }}
        className="about_text"
      >
        {/* <motion.h1 variants={itemVariants}>The Company</motion.h1> */}
        <h1>
          {text.map((el, i) => (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: i / 10,
              }}
              key={i}
            >
              <strong>{el}</strong>
            </motion.span>
          ))}
        </h1>
        <motion.h5 variants={itemVariants} className="quotes about__me">
          At J.rome Studios, operating under the umbrella of J.rome LLC, we are
          dedicated to realizing creative visions through exceptional web
          design, captivating logos, impactful branding solutions, eye-catching
          flyers, banners, and more. Our passion lies in crafting bespoke
          designs with meticulous attention to detail and an unwavering
          commitment to excellence. We aim not only to meet but to surpass our
          clients' expectations.
        </motion.h5>
        <motion.h5 variants={itemVariants} className="quotes about__me">
          Whether you are a small business seeking to establish your online
          presence or a seasoned entrepreneur looking to refresh your brand
          identity, we are here to assist you in standing out in today's
          competitive landscape. Let's collaborate to elevate your brand and
          make a lasting impression on your audience.
        </motion.h5>
      </motion.div>
      <div className="company about__me">
        <h2 style={{ fontWeight: "bold" }}>Established. | 2010</h2>
      </div>
    </>
  );
};

export default AboutScreen;
