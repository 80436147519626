const graphicData = [
  {
    id: 1,
    img: "https://i.ibb.co/h9HmXSM/aco-sign.png",
    text: "Averi and Co Wine Bar",
  },
  {
    id: 2,
    img: "https://i.ibb.co/YbzG1YH/blak-bcard.png",
    text: "Blak The Barber",
  },
  {
    id: 3,
    img: "https://i.ibb.co/41qC76v/wcm-smith2.png",
    text: "W.C.M.",
  },
  {
    id: 4,
    img: "https://i.ibb.co/Pt2LbTz/wcm-smith-cards2-blur.png",
    text: "W.C.M. Business Cards",
  },
  {
    id: 5,
    img: "https://i.ibb.co/7jjJMTn/dex-cards.png",
    text: "Dex - Trendsetters Barbershop",
  },
  {
    id: 6,
    img: "https://i.ibb.co/bND77J0/lynne-flyer1.png",
    text: "Lynne's Confection Perfection",
  },
  {
    id: 7,
    img: "https://i.ibb.co/6sJCf9W/pair-card.png",
    text: "2023 Classics",
  },
  {
    id: 8,
    img: "https://i.ibb.co/dpfK5Qb/dex-bg.png",
    text: "Dex - Trendsetters Barbershop 2020",
  },
  {
    id: 9,
    img: "https://i.ibb.co/fDRwtmT/angie1.png",
    text: "Angie's Market",
  },
  {
    id: 10,
    img: "https://i.ibb.co/mJZFJD5/jrome-note.png",
    text: "J.rome Brand",
  },
  {
    id: 11,
    img: "https://i.ibb.co/ZT6Zwj3/stu-hoodie-jrome.png",
    text: "J.rome Studio Hoodie",
  },
  {
    id: 12,
    img: "https://i.ibb.co/f9rNF1G/spiked-lemons.png",
    text: "Summer Party Flyers",
  },
  {
    id: 13,
    img: "https://i.ibb.co/WBCbdyb/smith-letterhead.png",
    text: "WCM Letterhead",
  },
];

export default graphicData;
