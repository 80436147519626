import React from "react";
import { Link } from "react-router-dom";
import { FaRegHandPointLeft } from "react-icons/fa";
import { Helmet } from "react-helmet-async";
import Meta from "../components/Meta";

import { motion } from "framer-motion";

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const RefundScreen = () => {
  return (
    <>
      <Meta title="J'rome Refund Policy" />
      <Helmet>
        <link rel="canonical " href="/refund" />
      </Helmet>
      <div
        style={{
          display: "flex",
        }}
      >
        <Link
          to="/home"
          style={{
            textDecoration: "none",
            color: "chocolate",
            display: "flex",
            lineHeight: "1",
          }}
        >
          <FaRegHandPointLeft
            style={{ color: "chocolate", marginRight: "5px" }}
          />
          Go Back
        </Link>
      </div>
      <motion.div
        initial="hidden"
        animate="visible"
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <motion.div
          style={{ textAlign: "center", margin: "3rem 0 3rem 0" }}
          variants={itemVariants}
        >
          <strong>Exchange Only Refund Policy</strong>
        </motion.div>
        <motion.div variants={itemVariants}>
          <h6>
            Thank you for shopping at <strong>J'rome LLC</strong>. We want you
            to be completely satisfied with your purchase. Due to the nature of
            our products, we offer exchanges only and do not provide refunds.
            Please review our exchange policy below:
          </h6>
          <strong>Eligibility for Exchanges</strong>
          <ul>
            <li>
              <strong>Time Frame:</strong>
              <h6>
                You may request an exchange within 30 days of the original
                purchase date.
              </h6>
            </li>
            <li>
              <strong>Condition of Item:</strong>
              <h6>
                The item must be unused, in its original packaging, and in the
                same condition as received.
              </h6>
            </li>
          </ul>
          <strong>Exchanges Process</strong>
          <ol>
            <li>
              <strong>Contact Us:</strong>
              <h6>
                To initiate an exchange, please contact our customer service
                team at jromestudios1@gmail.com with your order details and
                reason for exchange.
              </h6>
            </li>
            <li>
              <strong>Return Authorization:</strong>
              <h6>
                We will provide you with a Return Authorization (RA) number and
                instructions for returning the item.
              </h6>
            </li>
            <li>
              <strong>Return Shipment:</strong>
              <h6>
                Ship the item back to us using a trackable shipping method. The
                return shipping costs are the responsibility of the customer.
              </h6>
            </li>
            <li>
              <strong>Inspection and Exchange: </strong>
              <h6>
                Once we receive the returned item and verify its condition, we
                will process the exchange and ship the replacement item to you.
              </h6>
            </li>
          </ol>
          <strong>Exclusions</strong>
          <ul>
            <li>
              <strong>Final Sale Items:</strong>
              <h6>Items marked as final sale are not eligible for exchange.</h6>
            </li>
            <li>
              <strong>Damaged or Defective Items:</strong>
              <h6>
                If you receive a damaged or defective item, please contact us
                immediately for assistance.
              </h6>
            </li>
          </ul>
          <strong>Contact Us</strong>
          <h6>
            If you have any questions about our exchange policy or need
            assistance with an exchange, please contact our customer service
            team at jromestudios1@gmail.com.
          </h6>
          <div style={{ textAlign: "center", marginBottom: "3rem" }}>
            <strong style={{ fontStyle: "italic" }}>
              *Please note that our exchange policy is subject to change without
              notice.
            </strong>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default RefundScreen;
