import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCollapse } from "react-collapsed";
import { Col } from "react-bootstrap";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { FaCaretRight } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";

import jrome from "../assests/imgs/jrome.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [privacyExpanded, setPrivacyExpanded] = useState(false);
  const [companyExpanded, setCompanyExpanded] = useState(false);

  const privacyCollapse = useCollapse({ isExpanded: privacyExpanded });
  const companyCollapse = useCollapse({ isExpanded: companyExpanded });

  const handlePrivacyToggle = () => {
    setPrivacyExpanded((prev) => !prev);
  };

  const handleCompanyToggle = () => {
    setCompanyExpanded((prev) => !prev);
  };

  return (
    <>
      <Col style={{ marginLeft: "3rem" }}>
        <h5 onClick={handlePrivacyToggle} className="privacy-terms">
          Privacy & Terms{" "}
          {privacyExpanded ? (
            <FaSortDown className="privacy-terms-icon-after" />
          ) : (
            <FaCaretRight className="privacy-terms-icon-before" />
          )}
        </h5>
        <div {...privacyCollapse.getCollapseProps()}>
          <ul style={{ listStyleType: "none", marginLeft: "-2rem" }}>
            <li>
              <a
                href="/privacy"
                style={{
                  textDecoration: "none",
                  color: "gray",
                }}
              >
                <h6
                  style={{
                    margin: "0",
                    fontSize: "12px",
                    textTransform: "uppercase",
                  }}
                >
                  Privacy Policy
                </h6>
              </a>
            </li>
            <li>
              <a
                href="/refund"
                style={{
                  textDecoration: "none",
                  color: "gray",
                }}
              >
                <h6
                  style={{
                    margin: "0",
                    fontSize: "12px",
                    textTransform: "uppercase",
                  }}
                >
                  Refund Policy
                </h6>
              </a>
            </li>
          </ul>
        </div>
      </Col>
      <Col style={{ marginLeft: "3rem" }}>
        <h5 onClick={handleCompanyToggle} className="privacy-terms">
          FAQs
          {companyExpanded ? (
            <FaSortDown className="privacy-terms-icon-after" />
          ) : (
            <FaCaretRight className="privacy-terms-icon-before" />
          )}
        </h5>
        <div {...companyCollapse.getCollapseProps()}>
          <ul style={{ listStyleType: "none", marginLeft: "-2rem" }}>
            <li>
              <a
                href="/company"
                style={{
                  textDecoration: "none",
                  color: "gray",
                }}
              >
                <h6
                  style={{
                    margin: "0",
                    fontSize: "12px",
                    textTransform: "uppercase",
                  }}
                >
                  Product Information
                </h6>
              </a>
            </li>
          </ul>
        </div>
        {/* <div onClick={handleScrollToTop}>UP</div> */}
      </Col>
      <Col
        style={{
          marginLeft: "3rem",
          fontSize: "10px",
        }}
      >
        <Link
          className="quotes"
          style={{ textDecoration: "none", color: "black" }}
          to="/unsubscribe"
        >
          Email Unsubscribe
        </Link>
      </Col>
      <hr style={{ position: "relative", top: "1rem" }} />
      <footer>
        <p className="owned">
          Owned and Operated by <img src={jrome} alt="jrome brand" />
        </p>
        <div className="socialMedia">
          <a
            href="https://www.instagram.com/smoov_cashus/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BiLogoInstagramAlt style={{ color: "black" }} />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61558437730495"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiFacebook style={{ color: "black" }} />
          </a>
        </div>
        <p className="trademark">
          &trade;{currentYear} <sub style={{ fontSize: "20px" }}>J</sub>.rome
          LLC. ALL RIGHTS RESERVED
        </p>
      </footer>
    </>
  );
};

export default Footer;
