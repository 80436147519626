import { Helmet } from "react-helmet-async";

const Meta = ({ title, description, keywords, page }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description}></meta>
      <meta name="keywords" content={keywords}></meta>
      <link rel="canonical" href="/home" />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Welcome to J'rome Studios",
  description:
    "Explore unique creations by J'rome LLC. Discover curated collections of art, fashion, and more by Reginald Dixon.",
  keywords:
    "clothing, logos, ecommerce, graphic design, photography, web design",
  page: "J'rome",
};

export default Meta;
