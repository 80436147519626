import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const IntroScreen = () => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      style={{
        textAlign: "center",
        marginTop: "5rem",
      }}
    >
      <motion.h1 variants={itemVariants} style={{ fontSize: "8rem" }}>
        Welcome
      </motion.h1>
      <Link
        to="/home"
        style={{ textDecoration: "none", color: "black" }}
        className="quotes"
      >
        <motion.p variants={itemVariants} style={{ fontSize: "1.5rem" }}>
          [ Enter ]
        </motion.p>
      </Link>
    </motion.div>
  );
};

export default IntroScreen;
