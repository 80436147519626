import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const CustomModal = ({ show, handleClose, title, listItems, onCloseText }) => {
  return (
    <Modal show={show} onHide={handleClose} className="quotes">
      <Modal.Title style={{ textAlign: "center" }}>{title}</Modal.Title>
      <Modal.Body>
        {listItems && (
          <ul>
            {listItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {onCloseText || "Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
