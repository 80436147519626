import { useState } from "react";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import { Bounce, ToastContainer, toast } from "react-toastify";

// Define validation schema with Yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const Subscribe = ({ show, handleClose }) => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = { email };

    // Validate form data with Yup
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        const serviceId = "service_aqwvsb2";
        const templateId = "template_141opnr";
        const publicKey = "XkmZRXimJncn-Bm3z";

        const templateParams = {
          email: email,
        };

        emailjs
          .send(serviceId, templateId, templateParams, publicKey)
          .then(
            () => {
              toast.success("Thank you for subscribing");
              setEmail("");
              handleClose();
            },
            () => {
              toast.error("Failed to subscribe.");
            }
          )
          .catch((error) => {
            console.error("Error subscribing:", error);
          });
      })
      .catch((validationErrors) => {
        const errorMessages = {};
        validationErrors.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        setErrors(errorMessages);
      });
  };

  return (
    <div className={`subscribe ${show ? "show" : ""}`}>
      <form onSubmit={handleSubmit}>
        <div
          className="subscribe-content quotes"
          style={{ textAlign: "center" }}
        >
          <h5
            style={{
              textAlign: "center",
              width: "90%",
              margin: "1rem auto 1rem",
            }}
          >
            Subscribe to stay updated with our latest news and offers.
          </h5>
          <h6>Also get 15% off of your next graphic design invoice.</h6>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: "5px" }}
          />
          {errors.email && <div className="error-message">{errors.email}</div>}
          <br />
          <div className="btn__subscribe__container">
            <button className="btn-subscribe">Subscribe</button> <br />
            <button type="button" className="btn-nope" onClick={handleClose}>
              No thanks
            </button>
          </div>
        </div>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </div>
  );
};

export default Subscribe;
