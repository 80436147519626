const webData = [
  {
    id: 1,
    img: "https://i.ibb.co/hKfGrfM/jrome-app.png",
    text: "jrome application",
    url: "www.jrome-studios.com",
  },
  {
    id: 2,
    img: "https://i.ibb.co/wL4Zmjy/mock-app.png",
    text: "averi's lounge bar",
    url: "averis-lounge.netlify.app",
  },
  {
    id: 3,
    img: "https://i.ibb.co/4f9VWyG/weather-app.png",
    text: "weather application",
    url: "jrome-weather.netlify.app",
  },
];

export default webData;
