const photoData = [
  {
    id: 1,
    img: "https://i.ibb.co/5MDnTD9/DSCI0735.jpg",
    text: "Blue Flowers",
  },
  {
    id: 2,
    img: "https://i.ibb.co/XYKN6sq/DSCI0769.jpg",
    text: "Bees Believe",
  },
  {
    id: 3,
    img: "https://i.ibb.co/LNqjMH4/DSCI0868.jpg",
    text: "Dandelion's Shine too",
  },
  {
    id: 4,
    img: "https://i.ibb.co/ck0JxPR/DSCI0953.jpg",
    text: "From the block",
  },
  {
    id: 5,
    img: "https://i.ibb.co/6t1zwcJ/DSCI0801.jpg",
    text: "yellow flower",
  },
  {
    id: 6,
    img: "https://i.ibb.co/2kNbkWP/relax.jpg",
    text: "wood relax",
  },
];

export default photoData;
