const graphic = {
  itemOne: [
    { id: 1, name: "-Business Cards" },
    { id: 2, name: "-Social Media Post" },
    { id: 3, name: "-Post Card Flyers" },
    { id: 4, name: "-Logos" },
  ],
};

const photo = {
  itemTwo: [
    { id: 1, name: "-Portraits" },
    { id: 2, name: "-Small Ceremony Weddings" },
    { id: 3, name: "-Product Displays" },
    { id: 4, name: "-Inclusive Events" },
  ],
};

const web = {
  itemThree: [
    { id: 1, name: "-Landing Page" },
    { id: 2, name: "-Portfolio" },
    { id: 3, name: "-Blogs" },
    { id: 4, name: "-Informational" },
  ],
};

const listItems = [
  "Poster Designs",
  "Instagram Grids",
  "Restaurant Menus",
  "Packaging Designs",
  "Sticker Designs",
  "Letterheads",
  "Web Designs",
  "Motion Graphics",
  "Stories/Status",
];

const listAdviceItems = [
  "- Name ideas",
  "- Who's your target audience?",
  "- Create a plan",
  "- How will you market your product?",
  "- Organize a business model",
  "- How will you manage your products & services?",
  "- Brand your business*",
  "- Flow of finances",
];

const images = [
  "https://i.ibb.co/bQtN94n/visual-identity-mockup.png",
  "https://i.ibb.co/bQtN94n/visual-identity-mockup.png",
  "https://i.ibb.co/bQtN94n/visual-identity-mockup.png",
  "https://i.ibb.co/bQtN94n/visual-identity-mockup.png",
];

export { graphic, photo, web, listItems, listAdviceItems, images };
