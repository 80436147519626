import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import Loader from "../components/Loader";
// import FormContainer from "../components/FormContainer";
import { Helmet } from "react-helmet-async";
import { useLoginMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";
import Meta from "../components/Meta";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get("redirect") || "/home";

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate(redirect);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Meta title="J'rome Login" />
      <Helmet>
        <link rel="canonical " href="/login" />
      </Helmet>
      <div className="signIn">
        <h1>Sign In</h1>

        <Form onSubmit={submitHandler}>
          <Form.Group className="my-2" controlId="email">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Form.Label>Email Address</Form.Label>
              <span style={{ fontSize: "12px", color: "red" }}>
                login as "guest": type guest@mail
              </span>
            </div>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: "20rem" }}
            ></Form.Control>
          </Form.Group>

          <Form.Group className="my-2" controlId="password">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Form.Label>Password</Form.Label>
              <span style={{ fontSize: "12px", color: "red" }}>
                "guest password": type 1234
              </span>
            </div>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              disabled={isLoading}
              type="submit"
              className="update"
              style={{
                backgroundColor: "black",
                border: "none",
                marginTop: "1rem",
              }}
            >
              Sign In
            </Button>
          </div>

          {isLoading && <h2>Loading...</h2>}
        </Form>

        <Row className="py-3">
          <Col>
            New Customer?{" "}
            <Link
              to={redirect ? `/register?redirect=${redirect}` : "/register"}
            >
              Register
            </Link>
          </Col>
        </Row>
        <h6 className="blink" style={{ textAlign: "center", color: "red" }}>
          Only registered members recieve latest updates and reward discounts.
        </h6>
      </div>
    </>
  );
};

export default LoginScreen;
