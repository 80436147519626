import { Swiper, SwiperSlide } from "swiper/react";
import photoData from "../constants/photoConstant";
import { Link } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper/modules";

const PhotoGallery = () => {
  return (
    <>
      <br />
      <div className="quotes">
        <Link to="/services" style={{ textDecoration: "none", color: "black" }}>
          Services
        </Link>
        <br />
        <Link to="/graphic" style={{ textDecoration: "none", color: "black" }}>
          Graphic Gallery
        </Link>
        <br />
        <Link to="/web" style={{ textDecoration: "none", color: "black" }}>
          Web Gallery
        </Link>
      </div>
      <hr />
      <br />
      <div>
        <h1 style={{ textAlign: "center", fontSize: "3rem" }}>Photo Gallery</h1>
      </div>
      <br />
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {photoData.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="graphic__cube">
              <img
                src={item.img}
                alt={item.text}
                loading=""
                style={{
                  maxWidth: "50%",
                  maxHeight: "50%",
                  margin: "auto",
                  display: "block",
                  borderRadius: "5px",
                  aspectRatio: "1/1",
                }}
              />
            </div>
            <p
              className="quotes"
              style={{
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {item.text}
            </p>
          </SwiperSlide>
        ))}
      </Swiper>
      <br />
      <br />
    </>
  );
};

export default PhotoGallery;
